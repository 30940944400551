import React from 'react'

import FeedbackForm from './FeedbackForm'

function ContactForm() {
  return (
<section id="contact" className="contact">
<div className="container aos-init aos-animate" data-aos="fade-up">

  <div className="section-header">
    <h2>Contact</h2>
    <div className="container">
      <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3859.9110872953!2d77.60624!3d14.660987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTTCsDM5JzM5LjYiTiA3N8KwMzYnMjIuNSJF!5e0!3m2!1sen!2sin!4v1684385504126!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </div>
  </div>

  <div className="row gx-lg-0 gy-4">

    <div className="col-lg-4">

      <div className="info-container d-flex flex-column align-items-center justify-content-center">
        <div className="info-item d-flex">
          <i className="bi bi-geo-alt flex-shrink-0"></i>
          <div>
            <h4>Location:</h4>
            <p>RTO Office Rd, Navodaya Colony, Anantapur, Andhra Pradesh 515002</p>
          </div>
        </div>

        <div className="info-item d-flex">
          <i className="bi bi-envelope flex-shrink-0"></i>
          <div>
            <h4>Email:</h4>
            <p>tanvithainfra@gmail.com</p>
          </div>
        </div>

        <div className="info-item d-flex">
          <i className="bi bi-phone flex-shrink-0"></i>
          <div>
            <h4>Call:</h4>
            <p>+91 8331047022</p>
          </div>
        </div>

        <div className="info-item d-flex">
          <i className="bi bi-clock flex-shrink-0"></i>
          <div>
            <h4>Open Hours:</h4>
            <p>Mon-Sat: 09AM - 05PM</p>
          </div>
        </div>
      </div>

    </div>

    <div className="col-lg-8">
        <FeedbackForm />
    </div>

  </div>

</div>
</section>
    )
}

export default ContactForm  