import './App.css';
import './Aos.css';
import './App.css';
import './Application.css';
import './Glightbox.css';
import './Glightbox.min.css';
import './Main.css';
import './Plyr.css';
import './Plyr.min.css';

import { Route, Routes } from 'react-router-dom';

import Topbar from './components/Topbar';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import AllProjects from './components/AllProjects';
import CompleteProjectDetails from './components/CompleteProjectDetails';
import BuyOrSell from './components/BuyOrSell';
import AboutUs from "./components/AboutUs";

function App() {

  return (
    <>
      <Topbar />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path="/projects/:id" element={ <CompleteProjectDetails />} />
        <Route path="/projects" element={<AllProjects />} />
        <Route path="/buyorsell" element={<BuyOrSell />} />
        <Route path="/aboutus" element={<AboutUs />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
