import React from 'react'

import IndexCanvas from './IndexCanvas'
import HomeMain from './HomeMain'

function Home() {
  return (
    <>
    <section id="hero" className="hero">
        <IndexCanvas />
    
    <div className="icon-boxes position-relative">
        <div className="container position-relative">
        <div className="row gy-4 mt-5">

            <div className="col-xl-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
            <div className="icon-box">
                <div className="icon">
                    <i className="bi bi-patch-check"></i>
                </div>
                <h4 className="title"><a href="/" className="stretched-link">Committed To Quality</a></h4>
                <p>We follow a strict policy to exercise the highest quality, not only in our projects but also in the services.</p>
            </div>
            </div>

            <div className="col-xl-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
            <div className="icon-box">
                <div className="icon">
                    <i className="bi bi-shield-lock"></i>
                </div>
                <h4 className="title"><a href="/" className="stretched-link">Intelligent Gated Communities</a></h4>
                <p>We’ve been leading the future of the Real Estate with our astonishing innovations and advances. We were the first movers to explore and implement many new avenues in the city.</p>
            </div>
            </div>

            <div className="col-xl-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
            <div className="icon-box">
                <div className="icon">
                    <i className="bi bi-view-list"></i>
                </div>
                <h4 className="title"><a href="/" className="stretched-link">Intelligent Planning</a></h4>
                <p>All the locations of our projects are strategically chosen to reduce the travelling distance to work. We have carefully planned and designed the architecture and layout of our projects to augment a smart lifestyle for our customers.</p>
            </div>
            </div>
        </div>
        </div>
    </div>

</section>


<HomeMain />

</>

    )
}

export default Home 