import React from 'react'

import Projects from './Projects'
import Faq from './Faq'
import ContactForm from './ContactForm'


function HomeMain() {
  return (
    <main id="main">
        <Projects />
        {/* <Faq /> */}
        <ContactForm />
    </main>
    )
}

export default HomeMain 