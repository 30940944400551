import React from 'react'

import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';


function Projects() {
    const [projects, setProjects] = useState([]);

    useEffect(() => {

        let config = {
            method: 'get',
            url: 'https://scary-trousers-foal.cyclic.app/api/projects',
            headers: { }
        };

        axios(config)
            .then((response) => {
                var data = JSON.stringify(response.data);
                setProjects(JSON.parse(data));
            })
            .catch((error) => {
            console.log(error);
        });

    }, [])

    const LoadProjects = () => {

        const projectsData = projects.map((project) => {
            return (
                <div className="col-12 col-lg-6 p-4">
                <div className="shadow-sm bg-white rounded p-4">
                    <img className="img-fluid mb-2 project-imgs" src={project.main_image} />
                    <h3>{project.name}</h3>
                    <p></p>
                    <div className="trix-content"></div>
                    <p></p>
                    <div className="row">
                        <Link to={`/projects/${project.id}`}>
                            <button className="cssbuttons-io-button"> Know More <div className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z"></path>
                                <path fill="currentColor" d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path>
                                </svg>
                            </div>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>  
            )
        })

        return projectsData
    }
    
  return (

    <section id="projects" className="services sections-bg">
        <div className="container aos-init aos-animate" data-aos="fade-up">

            <div className="section-header">
                <h2>Our Prime Projects</h2>
            </div>

            <div className="d-flex">
                <div className="row gy-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                    <LoadProjects />
                </div>
            </div>
        </div>
    </section>
    )
}

export default Projects;