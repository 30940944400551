import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useNavigate } from "react-router-dom";


export default function BuyOrSell() {

  const navigate = useNavigate();

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

//   const handleSubmit = (event) => {
//     event.preventDefault();
  
//     const myForm = event.target;
//     const formData = new FormData(myForm);
  
//     fetch("/", {
//       method: "POST",
//       headers: { "Content-Type": "application/x-www-form-urlencoded" },
//       body: new URLSearchParams(formData).toString(),
//     })
//       .then(() => {
//         alert("Thank you for your submission")
//         navigate("/")
//       })
//       .catch((error) => alert(error));
//   };

  return (
    <div className='container'>
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Help In Buying Property" value="1" />
            <Tab label="Need Help In Selling Property" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
            <form acceptCharset="UTF-8" method="post" action="https://formsubmit.co/475a5468d013395805ce2f5ebc32c206">
                <input type="hidden" name="_cc" value="tanvithainfra@gmail.com,stajuddinsha@gmail.com" />
                <input type="hidden" name="_subject" value="Help to buy property!!"></input>
                <input type="hidden" name="_captcha" value="false"></input>
                <input type="hidden" name="_autoresponse" value="Thank you for choosing TANVITHAINFRA. We will get back to you soon!"></input>
                <input type="hidden" name="_template" value="table"></input>

                <div className="row">
                <div className="col-lg-6 col-12">
                    <div className="field mb-3">
                    <label className="form-label fw-bold fs-6 text-uppercase" htmlFor="name">Name</label>
                    <input className="form-control" type="text" name="name" id="name" />
                    </div>
                </div>
                <div className="col-lg-6 col-12">
                    <div className="field mb-3">
                    <label className="form-label fw-bold fs-6 text-uppercase" htmlFor="email">Email</label>
                    <input className="form-control" type="email" name="email" id="email" />
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-lg-6 col-12">
                    <div className="field mb-3">
                    <label className="form-label fw-bold fs-6 text-uppercase" htmlFor="phone_number">Phone number</label>
                    <input className="form-control" type="text" name="phone_number" id="phone_number" />
                    </div>
                </div>
                <div className="col-lg-6 col-12">
                    <div className="field mb-3">
                    <label className="form-label fw-bold fs-6 text-uppercase" htmlFor="unit_type">Unit type</label>
                    <select className="form-select" name="unit_type" id="unit_type">
                        <option value="">Please select</option>
                        <option value="APARTMENT">APARTMENT</option>
                        <option value="PLOT">PLOT</option>
                        <option value="FORM LAND">FORM LAND</option>
                    </select>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-lg-6 col-12">
                    <div className="field mb-3">
                    <label className="form-label fw-bold fs-6 text-uppercase" htmlFor="budget">Budget</label>
                    <input className="form-control" type="text" name="budget" id="budget" />
                    </div>
                </div>
                <div className="col-lg-6 col-12">
                    <div className="field mb-3">
                    <label className="form-label fw-bold fs-6 text-uppercase" htmlFor="time_frame">Time frame</label>
                    <input className="form-control" type="date" name="time_frame" id="time_frame" />
                    </div>
                </div>
                </div>
                <div className="field mb-3">
                <label className="form-label fw-bold fs-6 text-uppercase" htmlFor="location">Location</label>
                <textarea className="form-control" name="location" id="location"></textarea>
                </div>
                <div className="actions">
                <input type="submit" name="commit" value="Submit" className="btn btn-primary w-100" data-disable-with="Submit" />
                </div>  
            </form>
        </TabPanel>
        <TabPanel value="2">
            <form acceptCharset="UTF-8" method="post" action="https://formsubmit.co/475a5468d013395805ce2f5ebc32c206">
                <input type="hidden" name="_cc" value="tanvithainfra@gmail.com,stajuddinsha@gmail.com" />
                <input type="hidden" name="_subject" value="Help to sell property!!"></input>
                <input type="hidden" name="_captcha" value="false"></input>
                <input type="hidden" name="_autoresponse" value="Thank you for choosing TANVITHAINFRA. We will get back to you soon!"></input>
                <input type="hidden" name="_template" value="table"></input>

                <div className="row">
                    <div className="col-lg-6 col-12">
                    <div className="field mb-3">
                        <label className="form-label fw-bold fs-6 text-uppercase" htmlFor="name">Name</label>
                        <input className="form-control" type="text" name="name" id="name" />
                    </div>
                    </div>
                    <div className="col-lg-6 col-12">
                    <div className="field mb-3">
                        <label className="form-label fw-bold fs-6 text-uppercase" htmlFor="phone_number">Phone number</label>
                        <input className="form-control" type="text" name="phone_number" id="phone_number" />
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-12">
                    <div className="field mb-3">
                        <label className="form-label fw-bold fs-6 text-uppercase" htmlFor="unit_type">Unit type</label>
                        <select className="form-select" name="unit_type" id="unit_type">
                        <option value="">Please select</option>
                        <option value="APARTMENT">APARTMENT</option>
                        <option value="PLOT">PLOT</option>
                        <option value="FORM LAND">FORM LAND</option>
                        </select>
                    </div>
                    </div>
                    <div className="col-lg-6 col-12">
                    <div className="field mb-3">
                        <label className="form-label fw-bold fs-6 text-uppercase" htmlFor="survey_number">Survey number</label>
                        <input className="form-control" type="text" name="survey_number" id="survey_number" />
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-12">
                    <div className="field mb-3">
                        <label className="form-label fw-bold fs-6 text-uppercase" htmlFor="selling_price_range">Selling price range</label>
                        <input className="form-control" type="text" name="selling_price_range" id="selling_price_range" />
                    </div>
                    </div>
                    <div className="col-lg-6 col-12">
                    <div className="field mb-3">
                        <label className="form-label fw-bold fs-6 text-uppercase" htmlFor="extent">Extent</label>
                        <input className="form-control" type="text" name="extent" id="extent" />
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="field mb-3">
                            <label className="form-label fw-bold fs-6 text-uppercase" htmlFor="email">Email</label>
                            <input className="form-control" type="email" name="email" id="email" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="field mb-3">
                            <label className="form-label fw-bold fs-6 text-uppercase" htmlFor="location">Location</label>
                            <textarea className="form-control" name="location" id="location"></textarea>
                        </div>
                    </div>                    
                </div>
                <div className="actions">
                    <input type="submit" name="commit" value="Submit" className="btn btn-primary w-100" data-disable-with="Submit" />
                </div>
            </form>
        </TabPanel>
      </TabContext>
    </Box>
    </div>
  );
}