import React from 'react'

function IndexCanvas() {
  return (
    <div class="iframe-slideshare-16x9">
      <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vSdBlItTd1qfx64lJFq48o18XdVYMuWwGmUBHKLxHym7POqYb9fOAMSasfIihuUpQTqMSQ6zzPo4YKA/embed?start=true&loop=true&delayms=3000&rm=minimal" frameBorder="0" allowFullScreen="true" mozallowFullScreen="true" webkitallowFullScreen="true"></iframe>
    </div>
    )
}

export default IndexCanvas  