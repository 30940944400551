import React from 'react'

function Footer() {
  return (
    <>
    <footer id="footer" className="footer">
        <div className="container mt-4">
            <div className="copyright">
                Copyright © 2023 <strong><span>Tanvitha Infra Projects Pvt. Ltd</span></strong>. All Rights Reserved
            </div>
        </div>
    </footer>
    <a  className="whats-app" href="https://wa.me/+918331047022" target="_blank">
        <i className="bi bi-whatsapp my-float"></i>
    </a>
    <a  className="facebook" href="https://www.facebook.com/tanvithainfra/" target="_blank">
        <i className="bi bi-facebook my-float"></i>
    </a>
    <a  className="youtube" href="https://www.youtube.com/@TANVITHAINFRAPROJECTS/featured" target="_blank">
        <i className="bi bi-youtube my-float"></i>
    </a>
    
    <a href="#" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
    </>
    )
}

export default Footer