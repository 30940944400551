import React from 'react'
import { Link } from "react-router-dom";

function Navbar() {
  return (
        <header id="header" className="header d-flex align-items-center sticky-top">

        <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <a href="/">
            <div className="logo d-flex align-items-center">
                <img src="logo.jpg" className='img-fluid' />
                <h1>Tanvitha</h1>
            </div>
        </a>
        <nav id="navbar" className="navbar ">
            <ul>
            <li>
                <Link to="/" className='active'>Home</Link>
            </li>
            <li>
                <Link to="/projects" className=''>Projects</Link>
            </li>
            {/* <li>
                <a href='/' className='' >Apartments on sale</a>
            </li> */}
            <li>
            <Link to="/buyorsell" className=''>Buy/Sell</Link>
            </li>
            {/* <li><a href="/#faq" className="">FAQ's</a></li> */}
            <li><a href="/#contact" className="">Contact</a></li>
            <li>
                <Link to="/aboutus" className=''>About Us</Link>
            </li>
            <li>
                <div className="row ms-1">

                <div className="search_group">
                    <svg className="search_icon" aria-hidden="true" viewBox="0 0 24 24">
                        <g>
                            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                        </g>
                    </svg>
                </div>
            
                </div>
            </li>
            </ul>

        </nav>

        <i className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
        <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>

        </div>
        </header>


    )
}

export default Navbar