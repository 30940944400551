import React from 'react'

function Topbar() {
  return (

    <section id="topbar" className="topbar d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between">
            <div className="contact-info d-flex align-items-center">
                <i className="bi bi-envelope d-flex align-items-center"><a href="mailto:tanvithainfra@gmail.com">tanvithainfra@gmail.com</a></i>
                <i className="bi bi-phone d-flex align-items-center ms-4"><span>+91 8331047022</span></i>
            </div>
            <div className="social-links d-none d-md-flex align-items-center">
                <a href="https://twitter.com/Tanvitha36476" className="" target="_blank"><i className="bi bi-twitter"></i></a>
                <a href="https://www.facebook.com/tanvithainfra/" className=""><i className="bi bi-facebook" target="_blank"></i></a>
                <a href="https://www.instagram.com/tanvithainfraprojects/" className="" target="_blank"><i className="bi bi-instagram"></i></a>
                <a href="https://www.youtube.com/@TANVITHAINFRAPROJECTS/featured" className="" target="_blank"><i className="bi bi-youtube" target="_blank"></i></a>
                <a href="https://wa.me/+918331047022" className="" target="_blank"><i className="bi bi-whatsapp"></i></a>
            </div>
        </div>
    </section>
    
  )
}

export default Topbar