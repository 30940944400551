import React from 'react'

function FeedbackForm() {
  return (
    <form action="https://formsubmit.co/475a5468d013395805ce2f5ebc32c206" method="post" role="form" className="php-email-form">
        <input type="hidden" name="_cc" value="tanvithainfra@gmail.com,stajuddinsha@gmail.com" />
        <input type="hidden" name="_subject" value="Feedback!!"></input>
        <input type="hidden" name="_captcha" value="false"></input>
        <input type="hidden" name="_autoresponse" value="Thank you for your feedback!! It means a lot to us."></input>
        <input type="hidden" name="_template" value="table" />

        <div className="row">
            <div className="col-lg-6 form-group">
                <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required="" />
            </div>
            <div className="col-lg-6 form-group mt-3 mt-md-0">
                <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required="" />
            </div>
        </div>
        <div className="row">
            <div className="form-group mt-3 col-lg-6">
                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required="" />
            </div>
            <div className="form-group mt-3 col-lg-6">
                <input type="text" className="form-control" name="mobile_number" id="mobile_number" placeholder="Mobile Number" required="" />
            </div>
        </div>
        <div className="form-group mt-3">
        <textarea className="form-control" name="message" rows="7" placeholder="Message" required=""></textarea>
        </div>
        <div className="my-3">
        <div className="loading">Loading</div>
        <div className="error-message"></div>
        <div className="sent-message">Your message has been sent. Thank you!</div>
        </div>
        <div className="text-center"><button type="submit">Send</button></div>
    </form>
    )
}

export default FeedbackForm