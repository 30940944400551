import React from 'react'
import {Helmet} from "react-helmet";

import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function AllProjects() {
    const [projects, setProjects] = useState([]);

    useEffect(() => {

        let config = {
            method: 'get',
            url: 'https://scary-trousers-foal.cyclic.app/api/projects',
            headers: { }
        };

        axios(config)
            .then((response) => {
                var data = JSON.stringify(response.data);
                setProjects(JSON.parse(data));
            })
            .catch((error) => {
            console.log(error);
        });

    }, [])

    const LoadProjects = () => {

        const projectsData = projects.map((project) => {
            return (
                <div className="card shadow p-3 mb-5 bg-body-tertiary rounded mt-4">
            
                    <figure className="figure tag tag-on-sale">
                        <img src={project.main_image} className="card-img-top figure-img" alt="image" />
                    </figure>

                    <div className="card-body">
                        <div className="row d-flex justify-content-space-between">                
                            <div className="col">
                                <p className="card-text">Project Name<br/><b>{project.name}</b><br /> {project.tag}</p>
                            </div>
                            <div className="col">
                                <Link to={`/projects/${project.id}`}>
                                    <button className="cssbuttons-io-button"> Know More
                                        <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path></svg>
                                        </div>
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col">
                                <p>Price <br />  <b> &#8377; {project.price_per_square }</b> </p>              
                            </div>
                            <div className="col">
                                <p>Plot Size <br /> <b> &#8377; {project.sqft} </b> </p>
                            </div>
                            <div className="col">
                                <p>Starting Price <br /> <b> &#8377; { project.starting_price }</b> </p>
                                                        
                            </div>
                        </div>
                        <div className="row bg-secondary-subtle p-2">
                            {project.short_description }
                        </div>
                    </div>
                </div>
            )
        })

        return projectsData
    }
    
  return (

    <>
        <Helmet>
            <title>Raaga County By Tanvitha Infra Projects | Villa plots for sale  in Anatapur</title>
            <meta name="DC.title" content='Raaga County By Tanvitha Infra Projects | Villa plots for sale  in Anatapur' />
            <meta name="description" content='Raaga County by  Tanvitha Infra projects is a Luxury Residential villa plots in Anantapur. Raaga county is wonderful option for the investments where your investment will create a bright future for you.' />
            <meta name="keywords" content='Plots for sale in Anantapur, plots for sale in Anatapur below 20 lakhs,residential plots for sale in Anatapur, Residential sites for sale in Anantapur' />
        </Helmet>

    <div className="container">
    <div className="row">
        <div className="col-12">
           <LoadProjects />
        </div>
    </div>
</div>

</>
    )
}

export default AllProjects;