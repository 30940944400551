import React from 'react'
import {Helmet} from "react-helmet";

import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";

function CompleteProjectDetails() {


    const [project, setProject] = useState([]);
    const [amenites, setAmenities] = useState([]);
    const { id } = useParams();


    const getProject = () => {
        let config = {
            method: 'get',
            url: `https://scary-trousers-foal.cyclic.app/api/projects/${id}`,
            headers: { }
        };

        axios(config)
            .then((response) => {
                var data = JSON.stringify(response.data);
                setProject(JSON.parse(data));
            })
            .catch((error) => {
            console.log(error);
        });
    }

    const getAmenities = () => {
        let config = {
            method: 'get',
            url: `https://scary-trousers-foal.cyclic.app/api/feachers`,
            headers: { }
        };

        axios(config)
            .then((response) => {
                var data = JSON.stringify(response.data);
                console.log(JSON.parse(data));
                setAmenities(JSON.parse(data));
            })
            .catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        getProject()
        getAmenities()
    }, [])


    const LoadAmenities = ()  => {

        const amenitiesList = amenites.map((amenity) => {
            return (
                <div className="row mb-2">
                    <div className="col-1">
                        <img src={amenity.img} alt="logo" className='' />
                    </div>
                    <div className="col-11">
                        <h5 className="card-title fw-bold">{ amenity.title }</h5>
                        <p className="card-text">{ amenity.about }</p>
                    </div>
                </div>
            )
        })
        return amenitiesList
    }

    const EnquireForm = () => {
        return (
            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Enquire Now</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form acceptCharset="UTF-8" method="post" action="https://formsubmit.co/475a5468d013395805ce2f5ebc32c206">
                            <input type="hidden" name="_cc" value="tanvithainfra@gmail.com,stajuddinsha@gmail.com" />
                            <input type="hidden" name="_subject" value="Enquire Submit!!"></input>
                            <input type="hidden" name="_captcha" value="false"></input>
                            <input type="hidden" name="_autoresponse" value="Thank you! We have recevied your query. We will get back to you soon!"></input>
                            <input type="hidden" name="_template" value="table"></input>

                            <div className="form-group mb-2">
                                <input className="form-control w-100" id="name" type="text" name="Name" placeholder='Enter your name' />
                            </div>
                            <div className="form-group mb-2">
                                <input className="form-control w-100" id="mobile_number" type="text" name="Mobile Number" placeholder='Enter your mobile number' />
                            </div>
                            <div className="form-group mb-2">
                                <input className="form-control w-100" id="email" type="email" name="Email" placeholder='Enter your email' />
                            </div>
                            <div className="form-group mb-2">
                                <textarea className="form-control w-100" id="message" name="Message" placeholder='Enter your message'></textarea>
                            </div>
                            <input className="btn btn-primary w-100 btn-sm text-bold" type="submit" value="Submit" />
                        </form>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
    
  return (

    <>
    <Helmet>
        <title>Raaga County By Tanvitha Infra Projects | Villa plots for sale  in Anatapur</title>
        <meta name="DC.title" content='Raaga County By Tanvitha Infra Projects | Villa plots for sale  in Anatapur' />
        <meta name="description" content='Raaga County by  Tanvitha Infra projects is a Luxury Residential villa plots in Anantapur. Raaga county is wonderful option for the investments where your investment will create a bright future for you.' />
        <meta name="keywords" content='Plots for sale in Anantapur, plots for sale in Anatapur below 20 lakhs,residential plots for sale in Anatapur, Residential sites for sale in Anantapur' />
    </Helmet>
        <div className='container' >
        <div className="row">

        <div className="col-12">

            <div className="card shadow p-3 mb-5 bg-body-tertiary rounded mt-4">

                <figure className="figure tag tag-on-sale">
                    <img src={project.main_image} className='card-img-top figure-img' alt="" />
                </figure>
            
                <div className="card-body">
                    <div className="row d-flex justify-content-space-between">                
                        <div className="col">
                            <p className="card-text">Project Name<br/><b>{ project.name }</b> <br /> { project.tag }</p>
                        </div>
                        <div className="col-lg-3 col">
                            <div className="row d-flex flex-row align-items-end">
                                <button type="button" class="btn btn-success mb-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                    <i className="bi bi-telephone m-2"></i>
                                    Enquire Now
                                </button>
                                    <EnquireForm />
                                    
                                    <a href={project.brochure} className='btn btn-info' download>
                                        <i className="bi bi-download m-2"></i>
                                        Download Brochure
                                    </a>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col">
                            <p>Price <br />  <b> &#8377; { project.price_per_square }</b> </p>

                        </div>
                        
                        <div className="col">
                            <p>Plot Size <br /> <b>{ project.sqft }</b></p>
                            
                        </div>
                        <div className="col">
                            <p>Starting Price <br /> <b> &#8377; { project.starting_price }</b> </p>
                                                    
                        </div>
                    </div>
                    <div className="row bg-secondary-subtle p-2 text-justify">
                        { project.about }
                    </div>
                </div>
            </div>

            <div className="card  shadow p-3 mb-5 bg-body-tertiary rounded mt-4">
                <h5 className="card-header fw-bold">AMENITIES</h5>
                <div className="card-body">
                    <LoadAmenities />
                </div>
            </div>

            <div className="card  shadow p-3 mb-5 bg-body-tertiary rounded mt-4">
                <h5 className="card-header fw-bold">SITE LAYOUT</h5>
                <div className="card-body">
                    <img src={project.site_layout} className='img-fluid' alt="" />
                </div>
                </div>

                <div className="card  shadow p-3 mb-5 bg-body-tertiary rounded mt-4">
                <h5 className="card-header fw-bold">LOCATION</h5>
                <div className="card-body">
                <iframe title='' src={ project.location } width="100%" height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>

        </div>

        </div>
        </div>
    </>
    )
}

export default CompleteProjectDetails;